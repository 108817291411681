import React from 'react';

const Header = () => {
    return (
        <header className="bg-alert">
            <h1>Buscador de recetas de bebidas</h1>
        </header>
    );
};

export default Header;
