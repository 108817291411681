import React, {createContext, useEffect, useState} from 'react';

import axios from 'axios';

//Crear el context
export const CategoriasContext = createContext();

//provaider es donde se enccuentran las funciones y el state
const CategoriasProvider = (props) => {

    // crear el state del Context
    const [categorias, guardarCategorias] = useState([]);

    //lamada a la api
    useEffect(() =>{
        const obtenerCategorias = async () => {
            const url= 'https://www.thecocktaildb.com/api/json/v1/1/list.php?c=list';
            const categorias = await axios.get(url);

            guardarCategorias(categorias.data.drinks);
        };

        obtenerCategorias();

    },[]);



    return (
        <CategoriasContext.Provider
            value={{
                categorias
            }}
        >
            {props.children}
        </CategoriasContext.Provider>
    );
};

export default CategoriasProvider;
